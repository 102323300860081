import * as React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const Lucy = () => (
  <Layout>
    <SEO title="Lucy" />
    <h1>Hi I'm Lucy</h1>
    <p>I'm 40 pounds at 4 months old. Adopted on March 9th, 2021</p>
    <div>
      <img src="https://res.cloudinary.com/angular/image/upload/c_scale,w_600/v1615769613/welovetonky/lucy.jpg" alt="Lucy" />
    </div>
    <Link to="/">Go back to the homepage</Link>
  </Layout>
)

export default Lucy
